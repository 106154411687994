//dApp
import { configPage as config } from "@MoonVault/dapp-widgets-lib";

//pages (test)
import
{
	Page_debugInfo,
	Page_test_general,
	Page_test_extended,
	Page_test_cards,
	Page_test_charts,
	Page_test_modals
} from "@MoonLabsDev/dapp-sdk-lib";

//pages
import
{
	Page_soon,
	Page_home,
	Page_tools_planetIX
} from "../../pages";

//modules
import
{
	Module_PlanetIX
} from "../modules";

const configPage =
{
	...config,

	title: "Moon Labs",
	description: "",
	themeColor: "#000000",
	logo: "/assets/page/logo.png",
	modules:
	[
		...config.modules,
		Module_PlanetIX
	],

	preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
	fonts:
    [
		"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
    ],
    pages:
    [
        { path: "home", page: Page_home },
        { path: "debugInfo", page: Page_debugInfo },

        { path: "tests/general", page: Page_test_general },
		{ path: "tests/extended", page: Page_test_extended },
        { path: "tests/cards", page: Page_test_cards },
        { path: "tests/charts", page: Page_test_charts },
        { path: "tests/modals", page: Page_test_modals },

        { path: "tools/planetIX", page: Page_tools_planetIX }
    ]
};

export default configPage;