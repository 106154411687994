//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction, MLUtils } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_PlanetIX";

//contracts
import ABI_MissionControl from "../abi/PlanetIX/MissionControl";
import ABI_Prospector from "../abi/PlanetIX/Prospector";

export const TileLevels =
{
	LAND: "land",
	GROUND: "ground",
	AIR: "air"
};

export class PlanetIX
{
	////////////////////////////////////

	constructor(dapp, data)
	{
		//init
		this.initialized = false;
		this.initializedUser = false;
        this.dapp = dapp;

		//values
		this.address_missionControl = data.missionControl;
		this.address_prospector = data.prospector;

		//const
		this.tileLevels =
		[
			TileLevels.LAND,
			TileLevels.GROUND,
			TileLevels.AIR
		];
		this.signature_collect = "CollectMessage(address _user,uint256 _nonce)";

		//missionControl
		this.nonce = 0;

		//user data
		this.userTiles = [];
		this.orders = [];

		//tiles
		this.tiles =
		[
			{
				ring: 1,
				tiles:
				[
					{ x: 0, y: -1, z: 1 }, //top left
					{ x: 1, y: -1, z: 0 }, //top right

					{ x: 1, y: 0, z: -1 }, //right
					{ x: 0, y: 1, z: -1 }, //bottom right

					{ x: -1, y: 1, z: 0 }, //bottom left
					{ x: -1, y: 0, z: 1 }, //left
				]
			},
			{
				ring: 2,
				tiles:
				[
					{ x: 0, y: -2, z: 2 }, //top left
					{ x: 1, y: -2, z: 1 },

					{ x: 2, y: -2, z: 0 }, //top right
					{ x: 2, y: -1, z: -1 },

					{ x: 2, y: 0, z: -2 }, //right
					{ x: 1, y: 1, z: -2 },

					{ x: 0, y: 2, z: -2 }, //bottom right
					{ x: -1, y: 2, z: -1 },

					{ x: -2, y: 2, z: 0 }, //bottom left
					{ x: -2, y: 1, z: 1 },

					{ x: -2, y: 0, z: 2 }, //left
					{ x: -1, y: -1, z: 2 },
				]
			},
			{
				ring: 3,
				tiles:
				[
					{ x: 0, y: -3, z: 3 }, //top left
					{ x: 1, y: -3, z: 2 },
					{ x: 2, y: -3, z: 1 },

					{ x: 3, y: -3, z: 0 }, //top right
					{ x: 3, y: -2, z: -1 },
					{ x: 3, y: -1, z: -2 },

					{ x: 3, y: 0, z: -3 }, //right
					{ x: 2, y: 1, z: -3 },
					{ x: 1, y: 2, z: -3 },

					{ x: 0, y: 3, z: -3 }, //bottom right
					{ x: -1, y: 3, z: -2 },
					{ x: -2, y: 3, z: -1 },

					{ x: -3, y: 3, z: 0 }, //bottom left
					{ x: -3, y: 2, z: 1 },
					{ x: -3, y: 1, z: 2 },

					{ x: -3, y: 0, z: 3 }, //left
					{ x: -2, y: -1, z: 3 },
					{ x: -1, y: -2, z: 3 },
				]
			}
		]
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `PlanetIX failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_MissionControl, this.address_missionControl).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address_missionControl,
            ABI_MissionControl,
            _calls
        );
    }

	getPropspectorContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_Prospector, this.address_prospector).methods;
    }

    makeProspectorMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address_prospector,
            ABI_Prospector,
            _calls
        );
    }

	/////////////////////////
    // Init
    /////////////////////////

    async batch_init()
    {
		if (this.initialized)
		{
			return;
		}
        this.initialized = true;
    }

	/////////////////////////
    // UserBase
    /////////////////////////

    async batch_userInfoBase()
    {
		await this.batch_init();
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_userInfoBase(),
            (o, r) => o.processRequest_userInfoBase(r),
            false,
            "[PlanetIX] userInfoBase",
            "PlanetIX: userInfoBase"
        );
    }

    makeRequest_userInfoBase()
    {
		const flat = this.flattenTiles();
        return [
			this.makeMultiCall(
			{
				nonce: { function: "nonces", parameters: [this.dapp.account] },
				...flat.reduce((p, c) =>
					{
						return {
							...p,
							...this.tileLevels.reduce((pl, cl) =>
								{
									return {
										...pl,
										[`tile_${c.x}_${c.y}_${cl}_tiles`]:
										{
											function: "tiles",
											parameters:
											[
												this.dapp.account,
												c.x,
												c.y,
												this.tileLevels.indexOf(cl)
											]
										}
									}
								},
								{}
							)
						};
					},
					{}
				)
			}),
			this.makeProspectorMultiCall(
			{
				orders: { function: "getOrders", parameters: [this.dapp.account] }
			})
		];
    }

    async processRequest_userInfoBase(_data)
    {
        this.nonce = parseInt(_data.nonce);
		this.orders = _data.orders.map(o => (
			{
				id: parseInt(o[0]),
				amount: parseInt(o[1]),
				createdAt: parseInt(o[2])
			}
		));

		//tiles
		const flat = this.flattenTiles();
		flat.forEach(f =>
			{
				const t = this.findOrCreateUserTile(f.ring, f.x, f.y, f.z);
				this.tileLevels.forEach(l =>
					{
						const tl = {};
						const d_t = _data[`tile_${f.x}_${f.y}_${l}_tiles`];
						tl.tokenId =  parseInt(d_t.tokenId);
						tl.nonce = parseInt(d_t.nonce);
						t.levels[l] = tl;
					}
				);
			}
		);

        //event
        MLUtils.dispatchEvent(ModuleEvents.userData);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	getTileType(_tile)
	{
		if (!!_tile.levels[TileLevels.AIR]?.tokenId)
		{
			return "Drone";
		}
		else if (!!_tile.levels[TileLevels.GROUND]?.tokenId)
		{
			return "Building";
		}
		else if (!!_tile.levels[TileLevels.LAND]?.tokenId)
		{
			return "Land";
		}

		return "Empty";
	}

	flattenTiles()
	{
		const tiles = [];
		for (let n = 0; n < this.tiles.length; n++)
		{
			const ring = this.tiles[n];
			ring.tiles.forEach(t => tiles.push(
				{
					ring: ring.ring,
					x: t.x,
					y: t.y,
					z: t.z
				}
			))
		}
		return tiles;
	}

	findOrCreateUserTile(_ring, _x, _y, _z)
	{
		let t = this.userTiles.find(t => t.x === _x && t.y === _y && t.z === _z && t.ring === _ring) || null;
		if (!t)
		{
			t = {
				ring: _ring,
				x: _x,
				y: _y,
				z: _z,
				levels: {}
			}
			this.userTiles.push(t);
		}
		return t;
	}

	getCollectionOrder(_tiles)
	{
		if (!Array.isArray(_tiles))
		{
			_tiles = [_tiles];
		}
		return _tiles.reduce((p, c) =>
			{
				if (!!c.levels[TileLevels.GROUND]?.tokenId)
				{
					//energy
					p.push(
					{
						...c,
						position: 1
					});
				}
				else if (!!c.levels[TileLevels.LAND]?.tokenId)
				{
					//waste
					p.push(
					{
						...c,
						position: 0
					});
				}

				return p;
			},
			[]
		);
	}

	getUserTiles()
	{
		return this.userTiles.filter(t => !!t.levels[TileLevels.LAND]?.tokenId);
	}

	/////////////////////////
    // Signature
    /////////////////////////

	async getCollectSignature()
	{
		const ret = await MLUtils.fetchJSONFromForm(
			"https://api.planetix.com/api/v1/web3/collect-from-tiles/signature",
			JSON.stringify(
				{
					address: this.dapp.account.toLowerCase(),
					nonce: this.nonce,
					version: "v2"
				}
			),
			{
				"Content-Type": "application/json"
			}
		);

		return ret.data;
	}

	/////////////////////////
    // Transactions
    /////////////////////////

	collectFromTiles(_tiles, _sig)
    {
		//prepare
		const collect = this.getCollectionOrder(_tiles);
		const positions = collect.map(t => t.position);

        const con = this.getContract(true);
        return new Web3Transaction(
            con.collectFromTiles(
				collect,
				positions,
				_sig.v,
				_sig.r,
				_sig.s
			),
            this.debugErrorString("collectFromTiles"),
            `Collect`
		);
    }
}