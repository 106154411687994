//components
import
{
	Text,
	Link,
	Flex,
	Grid,
	Card,
	CardBody,
	PageHeader,
    ButtonVideoGuide
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLUtils, useDApp, useIsMobile, cx } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "../Components.module.css";

export const MVPageHeader = ({title, learnMore, backgroundImage, style, imageStyle, children}) =>
{
	//context
	const dApp = useDApp();
    const isMobile = useIsMobile();

	if (dApp.config.page.designMoonLabs)
	{
		return (
			<PageHeader
                title=
                {
                    <Flex>
                        {title}
						{!MLUtils.isEmptyString(learnMore) &&
							<Grid>
								<ButtonVideoGuide videoID={learnMore} />
							</Grid>
						}
                    </Flex>
                }
            >
                {children}
            </PageHeader>
		);
	}

	return (
		<Card
			className={cx([styles.header, {[styles.header__desktop]: !isMobile}])}
			style={style}
		>
			<CardBody
				className={cx([styles.header_background, {[styles.header_background__mobile]: isMobile}])}
				style=
				{
					{
						...imageStyle,
						...(backgroundImage && { backgroundImage: backgroundImage })
					}
				}
			>
				<Flex
					className=
					{
						cx([
							styles.header_textPanel,
							{[styles.header_textPanel__desktop]: !isMobile}
						])
					}
					gap="0px"
					direction="column"
					align="flex-start"
				>
					<Text
						color={3}
						size={2}
					>
						{title}
					</Text>

					<Text
						color={1}
						size={-1}
						style={{ flexGrow: 1 }}
					>
						{children}
					</Text>

					{!MLUtils.isEmptyString(learnMore) &&
						<Text
							color={3}
							size={0}
						>
							<Link
								href={learnMore}
							>
								Learn more &gt;
							</Link>
						</Text>
					}
				</Flex>
			</CardBody>
		</Card>
	);
};