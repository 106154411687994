//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { PlanetIX } from "../classes/PlanetIX";

export const ModuleEvents =
{
	userData: "planetIX_userData"
}

export class Module_PlanetIX extends DAppModule
{
    static moduleName = "planetIX";
    constructor()
    {
        super(m => m.planetIX);
        this.planetIX = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.planetIX === null)
        {
            return;
        }

        //userInfo
        if (dApp.account !== null)
        {
			//user/issuer
			await MLUtils.measureTime(`TM: PlanetIX => UserInfoBase`, async () =>
            {
                await this.planetIX.batch_userInfoBase();
            });
            await MLUtils.measureTime(`TM: PlanetIX => UserInfo`, async () =>
            {
                await this.planetIX.batch_userInfo();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_PlanetIX.availableOnChain(dApp.currentChain))
        {
            return;
        }
        dApp.log(false, `Loading PlanetIX`);
        this.planetIX = new PlanetIX(dApp, dApp.currentChain?.modules?.planetIX);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.planetIX;
        const result = (addr !== undefined);

        return result;
    }
}

export const usePlanetIX = () => useModule(Module_PlanetIX);

const currentModule = new Module_PlanetIX();
export default currentModule;