import configPage from "./page";

//components
import { ToolMenu } from "../components/ToolMenu";

const configMenu =
{
	toolComponent: ToolMenu,
    desktopMode:
    {
        header:
        {
            menu: "normal",
			tool: "normal",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    tabletMode:
    {
        header:
        {
            menu: "minimal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    mobileMode:
    {
        header:
        {
            menu: "none",
			tool: "none",
            user: "app"
        },
        footer:
        {
            menu: "app",
			tool: "none",
            user: "none"
        }
    },

	desktopMenu:
	[
		{
			label: "Home",
			link: "/"
		},
		{
			text: "Tools",
			children:
			[
				{
					text: "Planet IX",
					link: "/tools/planetIX"
				}
			]
		}
	],

	tabletMenu: undefined, //will use desktop instead

	mobileMenu: undefined, //will use desktop instead
};

export default configMenu;