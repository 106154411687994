import { useEffect, useState } from "react";

//components
import
{
    Text,
    Grid,
    Image,
	Link,
    Card,
    CardBody,
    Flex,
	Carousel,
	CardHeader,
	CardRibbon
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	SectionHeader
} from "../dApp/components";

//framework
import
{
	useIsMobile,
	cx,
	MLFormat,
	MLUtils
} from "@MoonLabsDev/dapp-core-lib";

//stlyes
import styles from "src/pages/Pages.module.css";

const Header = () =>
{
	const isMobile = useIsMobile();

	//const functions

    return (
        <Flex
			direction="row"
			className={cx([styles.homeHeader])}
        >
			<Flex direction="column" basis={isMobile ? "75%": "38%"}>
				<Text
					size={2}
					color={3}
					style={{ textShadow: "rgb(255 211 86) 0px 0px 10px" }}
				>
					Welcome to Moon Labs
				</Text>
				<Text size={0} style={{marginTop: "auto"}}>
					We help you build the future!
				</Text>
			</Flex>
        </Flex>
    );
};

export const Page_home = () =>
{
	//context
	const isMobile = useIsMobile();

	//functions
	const initVideo = () =>
	{
		const vid = document.getElementById('rotatingLogo');
		if (vid)
		{
			vid.play();
			vid.addEventListener('ended', () =>
			{
				vid.currentTime = 0;
				vid.play();
			}, false);
		}
	};

    return (
		<MVPage>

			<div onLoad={() => initVideo()}>
				<Image
					className={styles.homeBackground}
					src="assets/page/home/logo.png"
				/>
			</div>

			<Header />

		</MVPage>
    );
}