import { configLabels } from "@MoonVault/dapp-widgets-lib";

const translation =
{
	...configLabels.en_US,

	//payment_create
	modal_paymentCreate_title: "Create Invoice",
	modal_paymentCreate_memoPlaceholder: "Input memo text (optional)",
	modal_paymentCreate_create: "Create Invoice",

	//payment_view
	modal_paymentView_title: "View Invoice",
	modal_paymentView_pay: "Pay",
	modal_paymentView_qr: "Show QR Code",

	//payment_qr
	modal_paymentQR_title: "Receive Payment",
	modal_paymentQR_info: "Scan to Pay",

};

export default translation;