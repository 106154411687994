import { config } from "@MoonVault/dapp-widgets-lib";
import configPage from "./page";
import configMenu from "./menu";
import configUI from "./ui";
import configLabels from "./labels";

export default
{
	...config,

	labels: configLabels,
	page: configPage,
	menu: configMenu,
	ui: configUI
};