import { useState } from "react";

//components
import
{
	Flex,
	Grid,
	Text,
	Card,
	CardBody,
	CardHeader,
	ConnectWalletButton,
	Button,
	CheckTransactionButton,
	TokenPriceButton,
	TokenIcon
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";

//framework
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//classes
import { TileLevels } from "src/dApp/classes";

//modules
import { usePlanetIX, ModuleEvents_PlanetIX } from "src/dApp/modules";

const TileInfo = ({tile}) =>
{
	//context
	const planet = usePlanetIX();

	return (
		<InfoRow
			style=
			{
				{
					width: "250px",
					borderRadius: "5px",
					border: "1px solid var(--ML_page_colorAccent)",
					padding: "5px"
				}
			}
			title={`tile_${planet.userTiles.indexOf(tile)} @ ${tile.ring}`}
		>
			{planet.getTileType(tile)}
		</InfoRow>
	);
};

const InfoRow = ({title, className, style, children}) =>
{
	return (
		<Flex
			className={className}
			style={style}
			justify="space-between"
		>
			<Text>
				{title}:
			</Text>
			<Text>
				{children}
			</Text>
		</Flex>
	);
};

const PageContent = () =>
{
	//context
	const planet = usePlanetIX();

	//functions
	const getData = () =>
	{
		const r = {
			loaded: planet.userInitialized,
			nonce: planet.nonce,
			tiles: planet.userTiles,
			tilesFilled: planet.userTiles.filter(t => !!t?.levels[TileLevels.LAND]?.tokenId),
			orders: planet.orders
		}
		return r;
	};

	//state
	const [data, setData] = useState(() => getData());

	//handler
	const handleCollect = async() =>
	{
		const sig = await planet.getCollectSignature();
		planet.collectFromTiles(planet.getUserTiles(), sig).trySend();
	};

	//effects
	useEventSubscription(ModuleEvents_PlanetIX.userData, () => setData(getData()));

	return (
		<Flex>

			<Card style={{ width: "100%" }}>
				<CardHeader>
					Tiles
				</CardHeader>
				<CardBody>
					<Flex>
						{data.tilesFilled.map(t =>
							<TileInfo
								key={`tile_${data.tiles.indexOf(t)}`}
								tile={t}
							/>
						)}
					</Flex>
				</CardBody>
			</Card>

			<Card>
				<CardHeader>
					General Info
				</CardHeader>
				<CardBody>
					<InfoRow title="Nonce">
						{data.nonce}
					</InfoRow>
				</CardBody>
			</Card>

			<Card>
				<CardHeader>
					Actions
				</CardHeader>
				<CardBody>
					<Button
						checkZero={false}
						onClick={() => handleCollect()}
					>
						Collect
					</Button>
				</CardBody>
			</Card>
		</Flex>
	);
}

export const Page_tools_planetIX = () =>
{
	//context
	const dApp = useDApp();

    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Planet IX"
					imageStyle={{ backgroundPosition: "center" }}
					backgroundImage="url(https://planetix.com/planet-img-opacity15.png)"
				>
					As the UI of Planet IX is shit, I build something myself.
				</MVPageHeader>
			}
		>
            <MVCheckModule module={"planetIX"}>
				{dApp.account !== null
					? <PageContent />
					: <ConnectWalletButton />
				}
			</MVCheckModule>
        </MVPage>
    );
}