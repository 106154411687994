import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";

//template & theme
import { Template_OneImmortl } from "../../templates/OneImmortl";
import { Theme_OneImmortl } from "../../themes/OneImmortl";

const configUI =
{
	...configUI_sdk,

    template: Template_OneImmortl,
	theme: Theme_OneImmortl
};

export default configUI;